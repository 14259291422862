<template>
  <div class="page">
    <nav-bar :PitchOn="PitchOn"></nav-bar>
    <div class="main-info">
      <div class="info-left">
        <div class="guide-info">
          <el-breadcrumb>
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/article' }" v-if="type === '0'">文章</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/works' }" v-else-if="type === '1'">作品</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/OpenSource' }" v-else-if="type === '2'">开源项目</el-breadcrumb-item>
            <el-breadcrumb-item>文章详情</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
        <div class="article-info">
          <div class="title-info">
            <div class="title">
              <span>{{info.title}}</span>
            </div>
            <div class="info">
              <div class="desc">
                <span class="iconfont icon-zuozhe"></span>
                <span>{{info.author}}</span>
              </div>
              <div class="desc">
                <span class="iconfont icon-shijian"></span>
                <span>{{parseTime(info.create_time,'{y}-{m}-{d}')}}</span>
              </div>
              <div class="desc">
                <span class="iconfont icon-pinglun"></span>
                <span>{{info.comment_num}}人吐槽</span>
              </div>
              <div class="desc">
                <span class="iconfont icon-liulan"></span>
                <span>{{info.page_view}}观看</span>
              </div>
            </div>
          </div>
          <div class="content-info">
            <div class="markdown-body" v-html="info.content"></div>
          </div>
        </div>
        <div class="comment-info">
          <!--      留言输入-->
          <div class="input-area">
            <div class="input-name">
              <input type="text" v-model="commentForm.nickname" placeholder="请输入昵称">
            </div>
            <div class="input-content">
              <textarea v-model="commentForm.content" placeholder="请输入留言内容"></textarea>
            </div>
            <div class="submit-btn">
              <div class="btn" @click="onCommentSubmit">
                <span>提交</span>
              </div>
            </div>
          </div>
          <!--      留言列表-->
          <div class="leave-list">
            <div class="leave-title">
              <span>留言内容</span>
            </div>
            <div class="word-list">
              <div class="item" v-for="(item,index) in commentList" :key="index">
                <div class="user-date">
                  <div class="user">
                    <div class="pic">
                      <img src="@/assets/logo.png" alt="">
                    </div>
                    <div class="name">
                      <span>{{item.nickname}}</span>
                    </div>
                  </div>
                  <div class="date">
                    <span>{{parseTime(item.create_time,'{y}年{m}月{d}日')}}</span>
                  </div>
                </div>
                <div class="content">
                  <span class="tag"></span>
                  <span>{{item.content}}</span>
                </div>
              </div>
            </div>
            <div class="pagination">
              <el-pagination background layout="prev, pager, next"
                             @current-change="changePage"
                             :page-size="20"
                             :total="total">
              </el-pagination>
            </div>
          </div>
        </div>
      </div>
      <div class="info-right">
        <page-right></page-right>
      </div>
    </div>
  </div>
</template>

<script>
import NavBar from "../../components/NavBar/NavBar";
import PageRight from "../../components/PageRight/PageRight";
import {defineComponent,getCurrentInstance, onMounted, reactive, toRefs} from "vue";
import {ArticleCommentList, ArticleCommentPut, ArticleDetails} from "../../api/api";
import {useRoute} from "vue-router";
import { ElMessage } from 'element-plus'
export default defineComponent({
  name: "ArticleDetails",
  components: {PageRight, NavBar},
  setup(){
    const { proxy } = getCurrentInstance();
    const route = useRoute()
    const data = reactive({
      parseTime: proxy.util.parseTime,
      info: {},
      article_id: route.query.article_id||'',
      type: route.query.type||'0',
      PitchOn: '',
      commentList: [],
      commentForm: {
        nickname: '',
        content: '',
      },
      // 页面
      page: 1,
      total: 0,
      /**
       * 获取数据
       */
      getData(){
        ArticleDetails({
          article_id: data.article_id,
        }).then(res =>{
          data.info = res.data;
        })
      },
      /**
       * 获取评论数据
       */
      getComment(){
        ArticleCommentList({
          article_id: data.article_id,
          page: data.page,
        }).then(res =>{
          data.commentList = res.data.result;
          data.total = res.data.total;
        })
      },
      /**
       * 页码改变
       * @param page
       */
      changePage(page){
        data.page = page;
        data.getComment();
      },
      /**
       * 评论提交
       */
      onCommentSubmit(){
        if(!data.commentForm.nickname){
          ElMessage.error('请输入昵称');
          return;
        }
        if(!data.commentForm.content){
          ElMessage.error('请输入内容');
          return;
        }
        ArticleCommentPut({
          article_id: data.article_id,
          nickname: data.commentForm.nickname,
          content: data.commentForm.content,
        }).then(res =>{
          ElMessage.success(res.msg)
          this.commentForm.content = '';
          this.page = 1;
          this.getComment();
        })
      }
    })
    onMounted(() => {
      data.getData();
      data.getComment();
      switch (data.type){
        case "0":
          data.PitchOn = '文章';
          break;
        case "1":
          data.PitchOn = '作品';
          break;
        case "2":
          data.PitchOn = '开源项目';
          break;
      }
    })
    return {
      ...toRefs(data),
    }
  }
})
</script>

<style scoped lang="scss">
@import "ArticleDetails.scss";
</style>
